import React from "react"
import TestimonialSlide from "./testimonial-slide"
import { testimonials } from "./data"
import "./testimonials.css"
import Marquee from "react-fast-marquee"
import { MobileBreakpoint } from "../../breakpoints"

const Testimonials = ({ className }) => {
  return (
    <div className={`testimonials mb-[80px] md:mb-[120px] z-[11] relative ${className}`}>
      <h2 className="text-[20px] w-full max-w-[300px] mx-auto md:text-[30px] font-medium md:max-w-none mx-auto text-center">
        Read what our customers <MobileBreakpoint/> think of our services.
      </h2>
      <Marquee
        gradient={false}
        speed={50}
        className='pl-5 md:pl-[60px] gap-x-10'
        pauseOnHover
      >
        {testimonials.map((slideProps, index) => {
          return (
            <TestimonialSlide {...slideProps} />
          )
        })}
      </Marquee>
    </div>
  )
}

export default Testimonials