import React from "react"
import ctl from "@netlify/classnames-template-literals"

const TestimonialSlide = ({ text, image, author, origin }) => {
  return (
    <div className={containerStyle}>
      <p className={textStyle}>
        {text}
      </p>
      <div className="flex items-start gap-[10px] mt-auto">
        {image}
        <div>
          <p className={authorStyle}>
            {author}
          </p>
          <p className='text-base md:text-xl mt-0'>
            From our {origin}.
          </p>
        </div>
      </div>
    </div>
  )
}

const containerStyle = ctl(`
  px-[20px] py-[28px] md:py-8 1.5xl:px-[30px] shadow-testimonial bg-white h-full flex flex-col
  w-[90vw] md:w-[50vw] lg:w-[27.2vw]
`)

const textStyle = ctl(`font-normal text-lg 1.5xl:text-xl mb-5 md:mb-10 mt-0`)

const authorStyle = ctl(`
  font-bold text-base md:text-xl mb-[5px] mt-0
`)

export default TestimonialSlide