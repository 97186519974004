import React from "react"
import ctl from "@netlify/classnames-template-literals"

const MediaMentionsTitle = () => {
  const titleStyle = ctl(
    `font-extrabold text-base uppercase text-black mx-auto`
  )
  return <h3 className={titleStyle}>EGMARKETS IN THE NEWS</h3>
}

export default MediaMentionsTitle
