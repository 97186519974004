import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const imageClassName = 'w-[50px] h-[50px] md:w-[60px] md:h-[60px] rounded-[30px]'

export const testimonials = [
  {
    text: '@egmarkets you make trading so easy. Thank you.🙌🙌',
    image: (
      <StaticImage
        alt='Anjoyinoluwa'
        className={imageClassName}
        src='../../../assets/images/components/testimonial/anjoyin.webp'
      />
    ),
    author: 'Anjoyinoluwa',
    origin: 'Instagram'
  },
  {
    text: '@egmarkets signals 💯',
    image: (
      <StaticImage
        alt='Lawaldimeji'
        className={imageClassName}
        src='../../../assets/images/components/testimonial/lawal.webp'
      />
    ),
    author: 'Lawaldimeji',
    origin: 'Instagram'
  },
  {
    text: (
      <span>
        I’ll be happy if you win an <br className='hidden md:block'/> Award this year.
      </span>
    ),
    image: (
      <StaticImage
        alt='Anthony Ifeanyi Ogwuda'
        className={imageClassName}
        src='../../../assets/images/components/testimonial/anthony.webp'
      />
    ),
    author: 'Anthony Ifeanyi Ogwuda',
    origin: 'Facebook'
  },
  {
    text: '@egmarkets Thank you for prioritizing learning for beginners Thank you.🙌',
    image: (
      <StaticImage
        alt='Pipsprofitsandprosper'
        className={imageClassName}
        src='../../../assets/images/components/testimonial/guy.webp'
      />
    ),
    author: 'Pipsprofitsandprosper',
    origin: 'Instagram'
  },
]