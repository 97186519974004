import techcabalLogo from "assets/images/media/techcabal.webp"
import businessdayLogo from "assets/images/media/business-day.webp"
import nairametricsLogo from "assets/images/media/nairametrics.webp"
import proshareLogo from "assets/images/media/proshare.webp"
import punchLogo from "assets/images/media/punch.webp"
import vanguardLogo from "assets/images/media/vanguard.webp"
import bbcLogo from "assets/images/media/bbc.webp"
import cnbcLogo from "assets/images/media/cnbc.webp"

const companies = {
  "Techcabal": {
    name: "Techcabal",
    logo: techcabalLogo,
    url: "https://www.zikoko.com/money/5-nigerians-talk-about-how-trading-has-affected-their-lives/",
    className: 'w-[97px] md:w-[189px]'
  },
  "Businessday": {
    name: "Businessday",
    logo: businessdayLogo,
    url:
      "https://businessday.ng/markets/article/eagle-global-markets-seen-changing-financial-services-story-in-nigeria/",
    className: 'w-[101px] md:w-[198px]'
  },
  "Nairametrics": {
    name: "Nairametrics",
    logo: nairametricsLogo,
    url:
      "https://nairametrics.com/2021/07/05/eagle-global-markets-celebrating-5-years-of-excellence/",
    className: 'w-[124px] md:w-[293px]'
  },
  "Proshare": {
    name: "Proshare",
    logo: proshareLogo,
    url:
      "https://www.proshareng.com/news/CAPITAL%20MARKET/How-Egmarkets-is-Changing-the-Financial-Services-Story-in-Nigeria/58258",
    className: 'w-[110px] md:w-[215px]'
  },
  "Punch": {
    name: "Punch",
    logo: punchLogo,
    url:
      "https://punchng.com/eagle-global-markets-celebrate-five-years-of-excellence/",
    className: 'w-[89px] md:w-[174px]'
  },
  "Vanguard": {
    name: "Vanguard",
    logo: vanguardLogo,
    url:
      "https://www.vanguardngr.com/2021/07/sponsored-how-eagle-global-markets-is-changing-the-financial-services-story-in-nigeria/",
    className: 'w-[104px] md:w-[203px]'
  },
  "BBC news": {
    name: "BBC news",
    logo: bbcLogo,
    url:
      "https://www.bbc.com/news/world-africa-56169917",
    className: 'w-[65px] md:w-[126px]'
  },
  "CNBC news": {
    name: "CNBC news",
    logo: cnbcLogo,
    url:
      "https://www.cnbcafrica.com/2021/nigerias-sec-moves-to-stop-online-platforms-from-trading-in-foreign-securities/",
    className: 'w-[100px] md:w-[215px]'
  },
}

export const desktopCompanies = [
  companies["BBC news"],
  companies["Nairametrics"],
  companies["Businessday"],
  companies["Techcabal"],
  companies["CNBC news"],
  companies["Punch"],
  companies["Proshare"],
  companies["Vanguard"],
]

export const mobileCompanies = [
  companies["BBC news"],
  companies["Nairametrics"],
  companies["Businessday"],
  companies["Techcabal"],
  companies["CNBC news"],
  companies["Vanguard"],
  companies["Punch"],
  companies["Proshare"],
]
