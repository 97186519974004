import React from "react"
import MediaMentionsTitle from "./title"

import { desktopCompanies, mobileCompanies } from "./data"
import Container from "../container"
import ctl from "@netlify/classnames-template-literals"
import "./index.css"

const MediaMentions = ({ className }) => {
  const containerStyle = ctl(`
  relative z-[11] mb-20 ${className} rounded-[30px] 
   border border-brownish-grey bg-light-tangerine 
   pt-[30px] pb-10 px-[36px]
   lg:py-[56px] lg:!px-[115px]
   md:py-[56px] md:!px-[65px]
   max-w-[1174px] mx-auto
   mentions-container
   `)

  const renderCompany = (company, index) => {
    const { url, logo, name, className } = company

    const logoStyle = ctl(
      `md:opacity-50 hover:opacity-100 transition-opacity duration-300`
    )
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={`inline-flex !items-center ${className}`}
        key={index}
      >
        <img src={logo} alt={name} className={logoStyle} />
      </a>
    )
  }

  const mobileRow = ctl(`
    flex md:hidden justify-between flex-wrap mt-10
    gap-y-[40px]
  `)

  return (
    <Container size="large">
      <div className={containerStyle}>
        <MediaMentionsTitle />

        <div className="flex md-max:hidden items-center gap-x-[30px] gap-y-5 mt-12 justify-between">
          {desktopCompanies.slice(0, 4).map(renderCompany)}
        </div>
        <div className="flex md-max:hidden items-center gap-x-[80px] gap-y-5 mt-8 justify-between">
          {desktopCompanies.slice(4).map(renderCompany)}
        </div>
        <div className={mobileRow}>
          {mobileCompanies.slice(0, 2).map(renderCompany)}
        </div>
        <div className={mobileRow}>
          {mobileCompanies.slice(2, 4).map(renderCompany)}
        </div>
        <div className={mobileRow}>
          {mobileCompanies.slice(4, 6).map(renderCompany)}
        </div>
        <div className={mobileRow}>
          {mobileCompanies.slice(6).map(renderCompany)}
        </div>
      </div>
    </Container>
  )
}

export default MediaMentions
